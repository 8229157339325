<template>
    <div class="form-page">
        <div style="position: fixed; right: 10px;top:20px">
            <router-link to="/lang" class="btn btn-white " v-if="select_lang > 0">
                <span class="iconfont icon-yuyan"></span>
                <span>{{$t('选择语言')}}</span>
            </router-link>
        </div>
        <div class="form-top u-m-b10" style="padding-top: 100px">
            {{ $t('Welcome') }}！
        </div>
        <div class="login-panel">
            <div class="form-card form-card-welcome">
                <van-form class="login-form" @submit="handleClickLogin" v-if="false">
                    <van-field v-if="radio == '2'" v-model="username" center :placeholder="$t('请输入你的手机号码')" type="digit">
                        <template #label>
                            <div class="sw-label" @click="showPicker = true">
                                <span class="lan-n">{{ defaultCountry.value }}</span>
                                <span class="iconfont icon-down1"></span>
                            </div>
                        </template>
                    </van-field>
                    <van-field v-else v-model="username" :placeholder="$t('请输入Email')">
                    </van-field>
                    <van-field v-model="password" center :placeholder="$t('请输入密码')" v-if="sWpwd">
                        <template #button>
                            <van-icon name="eye" size="20px" color="#ebd675" @click.native="sWpwd = false" />
                        </template>
                    </van-field>
                    <van-field type="password" v-model="password" center :placeholder="$t('请输入密码')" v-else>
                        <template #button>
                            <van-icon name="closed-eye" size="20px" color="#ebd675" @click.native="sWpwd = true" />
                        </template>
                    </van-field>
                    <van-field v-if="gcode_show" v-model="gcode" center :placeholder="$t('请输入谷歌验证码')">
                    </van-field>
                    <div class="van-foot">
                        <router-link class="text-gray" to="/find-pwd">{{ $t('忘记密码?') }}</router-link>
                    </div>
                </van-form>
                <div class="form-foot">
                    <div class="form-foot-button u-m-b10">
                        <van-button @click="masklogin" color="linear-gradient(to right, #3270e0, #3653ea)"  block>
                            <div class="flex-center">
                                <img src="../assets/pictures/MetaMask_Fox.png" class="mask-icon" alt="">
                                <van-loading color="#ffffff"  size="24px" v-if="loading"></van-loading>
                                <span v-else>{{ $t('MetaMask登陆') }}</span>
                            </div>
                        </van-button>
                    </div>
                </div>
            </div>
        </div>
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker show-toolbar :columns="allCountries" @cancel="showPicker = false" @confirm="onConfirm"
                value-key="text" :confirm-button-text="$t('确认')" :cancel-button-text="$t('取消')">
            </van-picker>
        </van-popup>
    </div>
</template>

<script>
import { Dialog } from 'vant';
import Web3 from 'web3';
export default {
    components: {},
    data() {
        return {
            gcode_show: false,
            gcode: '',
            show: false,
            username: '',
            password: '',
            radio: '2',
            labelV: this.$t('手机号'),
            sWpwd: false,
            showPopover: false,
            showPicker: false,
            select_lang: false,
            defaultCountry: '+92',
            allCountries: [
                { text: 'Pakistan (‫پاکستان‬‎) +92', value: '+92' },
                { text: 'India (भारत) +91', value: '+91' },
                { text: 'Bangladesh (বাংলাদেশ) +880', value: '+880' },
                { text: 'United States +1', value: '+1' },
                { text: 'Australia +61', value: '+61' },
                { text: 'Singapore +65', value: '+65' },
                { text: 'United Kingdom +44', value: '+44' },
                { text: 'Japan +81', value: '+81' },
                { text: 'South Korea (대한민국) +82', value: '+82' },
                { text: 'Saudi Arabia (‫المملكة العربية السعودية‬‎) +966', value: '+966' },
                { text: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎) +971', value: '+971' },
            ],
            mail_captcha: 0,
            mail_register: 0,
            loading:true,
        }
    },
    methods: {
        changeRadio(val) {
            if (val == '1') {
                this.labelV = this.$t('邮箱');
            } else {
                this.labelV = this.$t('手机号');
            }
            this.radio = val;
            this.username = '';
        },
        onClickLeft() {
            this.$router.go(-1)
        },
        onConfirm(value) {
            this.showPicker = false;
            this.defaultCountry = value;
        },
        handleClickLogin() {
            if (this.username != "" && this.password != "") {
                this.doLogin(this.username, this.password);
            } else {
                this.$toast.fail(this.$t('请输入完整信息!'));
            }
        },
        async getConfig() {
            await this.$axios.get(this.$api_url.get_config).then(res => {
                if (res.data.code == 0) {
                    var data = res.data.data;
                    for (const key in data) {
                        localStorage.setItem(key, data[key]);
                    }
                    window._vue.$emit('getWhs');
                }
            });
        },
        doLogin(username, password) {
            let loading = this.$toast.loading();
            this.$axios
                .post(
                    this.$api_url.login,
                    this.$qs.stringify({
                        username: username,
                        country: this.defaultCountry,
                        password: password,
                        gcode: this.gcode,
                    })
                )
                .then((res) => {
                    if (res.data.code == 0) {
                        if (res.data.data.gcode_auth == 1) {
                            this.gcode_show = true;
                            return;
                        }
                        if (this.checked) {
                            localStorage.setItem('remember_username', username);
                        } else {
                            localStorage.setItem('remember_username', '');
                        }
                        this.$toast.success(res.data.msg);
                        this.$helper.setToken(res.data.data);
                        this.getConfig();
                        location.href = "/#/";
                    } else {
                        this.$toast.fail(res.data.msg);
                    }
                })
                .catch((ex) => {
                    this.$toast.fail(ex.data.msg);
                });
        },
        getCountryCode() {
            this.$axios
                .get('/country_code?type=login')
                .then((res) => {
                    if (res.data.code == 0) {
                        this.allCountries = res.data.data.list;
                        this.defaultCountry = res.data.data.default;
                    }
                });
        },
        async masklogin(){
            if (window.ethereum) {
                try {
                // 如果需要，请求账户访问权限
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                // 创建一个web3实例
                const web3 = new Web3(window.ethereum);
                // 获取当前账户
                const accounts = await web3.eth.getAccounts();
                if (accounts.length > 0) {
                    const account = accounts[0];
                    console.log('已连接账户:', account);
                    this.doRegister(account);
                    this.loading = false;
                    return account;
                } else {
                    console.error('未找到账户');
                }
                } catch (error) {
                    console.error('连接MetaMask出错:', error);
                }
            } else {
                this.showMetaMaskInstallMessage();
            }
        },
        doRegister(username){
            this.$toast.loading(this.$t('连接中'))
            this.$axios
                .post('/wallet/login', this.$qs.stringify({
                        username: username,
                        share_code: this.share_code,
                    })
                )
                .then((res) => {
                    if (res.data.code == 0) {
                        this.$toast.success(res.data.msg);
                        this.$helper.setToken(res.data.data);
                        setTimeout(function () {
                                location.href = "/#/";
                            }, 1000);
                    } else {
                        this.$toast.fail(this.$t(res.data.msg))
                    }
                })
                .catch((ex) => {
                    this.$toast.fail(ex.message);
                });
        },
        showMetaMaskInstallMessage() {
            const userAgent = navigator.userAgent;
                Dialog.confirm({
                    className:'darkDialog',
                    title: '',
                    message: this.$t('请先下载MetaMask应用'),
                    confirmButtonText:this.$t('确认'),
                    cancelButtonText:this.$t('取消'),
                    cancelButtonColor:'#ffffff',
                    confirmButtonColor:'#ffffff'
                })
                .then(() => {
                })
                .catch(() => {
                });
        }

    },
    mounted() {
        this.masklogin()
        this.select_lang = localStorage.getItem('app.select_lang') || false;
        this.mail_register = localStorage.getItem('app.mail_register') || 0;
        this.mail_captcha = localStorage.getItem('app.mail_captcha') || 0;
        if (typeof window.ethereum !== 'undefined') {
            console.log('MetaMask is installed!');
        }
    },
    created() {
        this.getCountryCode();
    }

}
</script>
<style scoped>
    ::v-deep .van-popover--light{
        background-color: transparent;
    }
    .form-foot{
        width: 100%;
    }
    .form-card-welcome{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 30vh;
    }
    .form-top{
        margin-bottom: 20px;
    }
</style>







